import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ImagesResponse } from "@sportyano/core/models/profiles-images-gallery/profiles.images-gallery.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class ProfilesService {
	constructor(private httpClient: HttpClient) {}

	getPublicGalleryImages(
		typeOfUser: "player" | "playground" | "academy",
		id: number
	): Observable<ImagesResponse> {
		return this.httpClient.get<ImagesResponse>(
			environment.baseUrl + typeOfUser + "/images-list/" + id
		);
	}
	getPublicGalleryVideos(
		typeOfUser: "players" | "playgrounds" | "academies",
		id: number
	): Observable<any> {
		return this.httpClient.get<ImagesResponse>(
			environment.baseUrl + typeOfUser + "/videos-list/" + id
		);
	}

	// get News for academy and playground
	public getPublicNews(typeOfUser: "players" | "playgrounds" | "academies",id:number): Observable<any> {
		return this.httpClient.get(`${environment.baseUrl}${typeOfUser}/news-list/${id}`)
	}

}
