import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService {

  constructor(private _httpClient:HttpClient) { }
  getAdvertisement(page:string,device:string){
      return this._httpClient.get<any>(`${environment.baseUrl}home-page/advertisment?page=${page}&device=${device}`)
  }
}
