import { CommonModule } from "@angular/common";
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Advertisement } from "@sportyano/shared/components/advertisement-card/models/advertisement";
import { CarouselModule } from "primeng/carousel";
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
@Component({
	selector: "e3lan-card",
	templateUrl: "./e3lan-card.component.html",
	styleUrl: "./e3lan-card.component.scss",
	standalone:true,
	imports:[CommonModule,ButtonModule,TagModule,CarouselModule],
	schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class E3lanCardComponent{
	
	@Input() advertisementData :Advertisement[];
	@Input() deviceType:string;

	@Input() advisementImage: string | string[];
	@Input() link:string;
	@Input() sectionNumber :number;
	@Input() advisementLayout: string='horizontal';
	@Input() customHeight: string='100%';
	@Input() customWidth: string = "80%";
	// dvice-type = "mobile"
	// order = 1

	ngOnChanges(changes: SimpleChanges): void {
		this.advertisementData = changes["advertisementData"].currentValue ;
		console.log(this.advertisementData);
		
		
		
	}
	  
	responsiveOptions:any[];
	ads:any[];
	ngOnInit(){
		


		
		
			this.responsiveOptions = [
				{
					breakpoint: '1199px',
					numVisible: 1,
					numScroll: 1
				},
				{
					breakpoint: '991px',
					numVisible: 1,
					numScroll: 1
				},
				{
					breakpoint: '767px',
					numVisible: 1,
					numScroll: 1
				}
			];
	}


	public navigateTO(url:string| null |undefined):void{
		if (url) {
			console.log('Navigating to URL:', url); // Debug line
			window.open(url,"_blank");
	}
}

}
