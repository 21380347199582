<div class="mb-0 h-full">
	<p-carousel [value]="advertisementData" [numVisible]="1" [showNavigators]="false" [numScroll]="1" [circular]="true"
	[autoplay]="true" [autoplayInterval]="3000" [responsiveOptions]="responsiveOptions"  class="h-full"
    [style]="{display:'block',height: '100%',width:'100%' }">
	<ng-template pTemplate="item" let-advertisement>
		<div *ngIf="advertisement.position === 'vertical'" class="h-full w-full"
			(click)="navigateTO(advertisement.link)"  [style]="{display:'block',height:'100%',width:'100%'}">
			<ng-container *ngIf="deviceType === 'desktop' && advertisement.desktop_image">
				<img [src]="advertisement.desktop_image" alt="Vertical Ad (Desktop)" class="h-full w-full object-cover" [style]="{height:'100%'}">
			</ng-container>
			<ng-container *ngIf="deviceType === 'mobile' && advertisement.mobile_image">
				<img [src]="advertisement.mobile_image" alt="Vertical Ad (Mobile)">
			</ng-container>
		</div>
		<div *ngIf="advertisement.position === 'horizontal'" class="ad-section w-full horizontal"
			(click)="navigateTO(advertisement.link)">

			<ng-container *ngIf="deviceType === 'desktop' && advertisement.desktop_image">

				<img [src]="advertisement.desktop_image" alt="Horizontal Ad (Desktop)">

			</ng-container>
			<ng-container *ngIf="deviceType === 'mobile' && advertisement.mobile_image">

				<img [src]="advertisement.mobile_image" alt="Horizontal Ad (Mobile)">
			</ng-container>
		</div>
	</ng-template>
</p-carousel>
</div>